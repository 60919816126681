import React,{Fragment} from 'react'
import { Toolbar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Grid } from "@mui/material";
import Container from 'react-bootstrap/Container';



//traduccion
import {useTranslation } from 'react-i18next'



export default function Lang() {

  const [t, i18n] = useTranslation("global")


  return (
    <Fragment>

         <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center"  marginTop={1} marginBottom={3} marginLeft={0} marginRight={0} > 
         
                <Toolbar variant="dense" >
                
                <h6 textAlign="center"><strong>{t("lang.select-lang")}: </strong></h6>
                <Avatar alt="arg"  src="/static/images/argentina.png" sx={{ml:2, mr:1}} onClick={() => i18n.changeLanguage("es")}/>

                <Avatar alt="brasil" src="/static/images/bandera-de-brasil.png"  sx={{mr:1}} onClick={() => i18n.changeLanguage("pt")}/>

                <Avatar alt="China" src="/static/images/china.png" sx={{mr:1}} onClick={() => i18n.changeLanguage("cmn")}/>

                <Avatar alt="eeuu" src="/static/images/estados-unidos-de-america.png" sx={{mr:1}}  onClick={() => i18n.changeLanguage("en")}/>
               
                </Toolbar>  
                
            </Grid>
            
    </Fragment>
  )
}
