import React, {Fragment, useState} from 'react'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Grid } from "@mui/material";


//traduccion
import {useTranslation } from 'react-i18next'



export function DateTimePicker(props) {

  
  const [t, i18n] = useTranslation("global")


  const [date, setDate] = useState('')
  const [time, setTime] = useState('')

  const {handleDateApp, handleTimeApp} = props
  
  console.log("esto esta en el estado fecha :" + date)
  console.log("esto esta en el estado horaMinutos :" + time)


    const datos = date
    const horaMinutos = time
    handleDateApp(datos)
    handleTimeApp(horaMinutos)
 

   
  return (

   
    <Fragment>
        <Stack component="form" noValidate spacing={3} marginBottom={0}>


          <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center"  marginTop={0} marginBottom={0} marginLeft={0} marginRight={0}>
               
                  <h6><strong> {t("DateTimePicker.select-d-t")} </strong></h6>
                
         </Grid>

         <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center"  marginTop={2} marginBottom={5} marginLeft={0} marginRight={0} >
                    <TextField
                        id="date"
                        label={t("DateTimePicker.date")}
                        type="date"
                        defaultValue={Date.now()}
                        onChange={ev => setDate(ev.target.value)} //ev => setDate(ev.target.value)
                        sx={{ width: 220 }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
 </Grid>


                    <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center"  marginTop={2} marginBottom={5} marginLeft={0} marginRight={0}> 
                    <TextField
                        id="time"
                        label={t("DateTimePicker.time-of-package")}
                        type="time"
                        defaultValue="00:00"
                        onChange={ev => setTime(ev.target.value)}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        inputProps={{
                        step: 300, // 5 min
                        }}
                        sx={{ width: 150 }}
                    />
                    </Grid>

                    
        </Stack>

        
        </Fragment>
  )
}
