import React, {useState} from "react";
import Lottie from "lottie-react";
import Container from 'react-bootstrap/Container';
import rollItChickenRoll from '../anim/rollItChickenRoll.json'
import { Grid } from "@mui/material";

//traduccion
import { useTranslation } from 'react-i18next'


export default function Anim(props) {

  const [t, i18n] = useTranslation("global")

  return (
    <Container >
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center"  marginTop={3} marginBottom={5}> 
        <Lottie  animationData={rollItChickenRoll} loop={true} />
          <h3 className='mt-5' > {t("Anim.Loading")} ...</h3>
      </Grid>
    </Container>
  )
}
