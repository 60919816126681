import React from 'react'
//import { Button } from 'react-bootstrap'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

//traduccion
import { useTranslation } from 'react-i18next'

export default function Refresh() {
  
  //seteo el estado para la traduccion
  const [t, i18n] = useTranslation("global")




    return (
    <div onClick={() =>window.location.reload()} className="mb-2">
        <ArrowBackIosNewIcon/> <strong className='mt-2'>{t("Refresh.back")}</strong>
    </div>
  )
}
