import React,{ useState, Fragment} from 'react'
import {Carousel} from 'react-bootstrap'
import Container from 'react-bootstrap/Container';
import { Grid } from "@mui/material";


//traduccion
import {useTranslation } from 'react-i18next'


const itemData = [
    {
        id: 0,
        img: '/static/images/_fadel.jpg',
        title: 'Seleccione un producto',
      },
    {
      id: 1,
      img: '/static/images/_pollo.png',
      title: 'Pollo Entero',
    },
    {
      id: 2,
      img: '/static/images/_cogotes.png',
      title: 'Cogote',
    },
    {
      id: 3,
      img: '/static/images/_corazones.png',
      title: 'Corazón',
    },
    {
      id: 4,
      img: '/static/images/_pechugas-3kg.png', //cambiar
      title: 'Pechugas',
    },
    {
      id: 5,
      img: '/static/images/_garras.png',
      title: 'Garras',
    },
    {
      id: 6,
      img: '/static/images/_higados.png',
      title: 'Higado',
    },
    {
      id: 7,  
      img: '/static/images/_cuartos-3-kg.png',
      title: 'Cuartos',
    },
    {
      id: 8,
      img: '/static/images/_pata-y-muslo.png',
      title: 'Pata Muslo',
    },
    {
      id: 9,
      img: '/static/images/_patitas-kg.png',
      title: 'Patitas',
    },
    {
      id: 10,
      img: '/static/images/_pechugas-3kg.png',
      title: 'Muslito',
    },
    {
      id: 11,
      img: '/static/images/_alas.png',
      title: 'Alas',
    },
    {
      id: 12,
      img: '/static/images/_cms.png',
      title: 'CMS congelada',
    }, 
    {
        id: 13,
        img: '/static/images/_centro de pechuga.png',
        title: 'Centro de pechuga',
    },
    {
        id: 14,
        img: '/static/images/_carcaza.png',
        title: 'Carcaza',
    },
    {
        id: 15,
        img: '/static/images/_muslo.png',
        title: 'Muslo',
    },
  ];



export default function ImageCarrousel(props) {

  const [t, i18n] = useTranslation("global")

  //seteo el estado para obtener el id
  const [id, setId] = useState('') 
  const [est, setEstado] = useState(false)

console.log("estado antes: "+ est)
  const cambioEstado = (id) =>{
    if(id !== 0){
      setEstado(true)
    }else{
      return
    }
  }
console.log("estado despues:" + est)
  //desestructuro las props
  const {handleIdProducto, handleEstadoLoading} = props
  handleIdProducto(id)
  handleEstadoLoading(est)

  
  


  return (
    <Fragment>
         <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center"  marginTop={0} marginBottom={0} marginLeft={0} marginRight={0}>
    {/* <Container >*/}
        <Carousel variant="dark" indicators={false} interval={null} >
        {itemData.map((item) => (
            <Carousel.Item key={item.id}>
            <img
                className="d-block w-100 mt-3 pt-3 pb-5 mb-5"
                src={`${item.img}?width=150&heigth=150&fit=crop&auto=format`}
                onClick={e => {setId(item.id)
                              cambioEstado(item.id)
                                    }}               
            />
             <Carousel.Caption>
                    <h6><strong>{t("ImageCarrousel.select-item."+`${item.id}`)}</strong></h6>
            </Carousel.Caption>
            
            </Carousel.Item>
           
            ))}
            
    </Carousel>

    
  {/*</Container>*/}
  </Grid>
  </Fragment>
  )
}
