import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import EggIcon from '@mui/icons-material/Egg';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TwitterIcon from '@mui/icons-material/Twitter';
import FactoryIcon from '@mui/icons-material/Factory';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Grid } from "@mui/material";
import Container from 'react-bootstrap/Container';
import Refresh from './Refresh';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrow } from '@fortawesome/free-solid-svg-icons'

import CheckIcon from '@mui/icons-material/Check';

import AnimDataNotFound from './AnimDataNotFound'

//importo use state
//import {useState} from 'react'


//traduccion
import {useTranslation } from 'react-i18next'



export function TimelineList({datosCrianza,fPostura, fCarga, fBajada, fFaena,estirpe,hPlayaFaena,localidad,provincia}) {
  const [t, i18n] = useTranslation("global")



  if((estirpe || fPostura || fCarga || 
    fBajada || localidad || provincia || hPlayaFaena || fFaena) === ''){
    return(
      <AnimDataNotFound/>
  )}
  else{

  return (


    <Timeline position="alternate" sx={{mt: 5, mr:0, ml: 0, p:0}}>

<Refresh/>


      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0', paddingLeft: '1', paddingRight:'1'}}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          <p className='MuiTypography-body2'>{t("TimelineList.Lineage")}: {estirpe}. {t("TimelineList.Position")}: {fPostura} RENSPA  07-016-0-55796/01<br></br>
           <CheckIcon color='success'/> < img src='/static/images/logo-senasa.jpg' alt="senasa"/></p>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <EggIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '30px', px: 2, paddingLeft: '1', paddingRight:'1'}}>
          <Typography variant="body2" component="span">
          <strong>{t("TimelineList.Reproductive-Farm")}.</strong>
          </Typography>
          <Typography className='MuiTypography-body2'>{t("TimelineList.Chicken-Breeding-Breeding-Farm")} Jubileo,Entre Ríos</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0', paddingLeft: '1', paddingRight:'1'}}
          variant="body2"
          color="text.secondary"
        >
          <p className='MuiTypography-body2'>{t("TimelineList.Official-Number")} SENASA: 2/220915 <br></br>
          <CheckIcon color='success'/><img src='/static/images/logo-senasa.jpg' alt="senasa"/> </p>
          
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <LocalShippingIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '30px', px: 2, paddingLeft: '1', paddingRight:'1' }}>
          <Typography variant="body2" component="span">
          <strong>{t("TimelineList.Truck")}:</strong>
          </Typography>
          <Typography className='MuiTypography-body2'> Fadel S.A ({t("TimelineList.Fertile-Egg")})</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' , paddingLeft: '1', paddingRight:'1'}}
          align="right"
          variant="body2"
          color="text.secondary"
        >
         <p className='MuiTypography-body2'>{t("TimelineList.Start")}: {fCarga} {t("TimelineList.Official-Number")} SENASA: 1/164825 <br></br>
           <CheckIcon color='success'/> <img src='/static/images/logo-senasa.jpg' alt="senasa"/></p>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          
          <TimelineDot color="warning" variant="filled" >
          <FontAwesomeIcon icon={faCrow} />
          {/*<FontAwesomeIcon icon={faCoffee}/>*/}
                       {/*<TwitterIcon />*/}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '30px', px: 2, paddingLeft: '1', paddingRight:'1' }}>
          <Typography variant="body2" component="span">
          <strong>{t("TimelineList.hatchery")}</strong>
          </Typography>
          <Typography className='MuiTypography-body2'> {t("TimelineList.hatchery")} - San José, Entre Ríos.</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' , paddingLeft: '1', paddingRight:'1'}}
          align="right"
          variant="body2"
          color="text.secondary"
        >
         <p className='MuiTypography-body2'>{t("TimelineList.Official-Number")} SENASA: 1/164825 <br></br>
         <CheckIcon color='success'/><img src='/static/images/logo-senasa.jpg' alt="senasa"/></p>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="secondary">
          <LocalShippingIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '30px', px: 2, paddingLeft: '1', paddingRight:'1'  }}>
          <Typography variant="body2" component="span">
          <strong>{t("TimelineList.Truck")}</strong>
          </Typography>
          <Typography className='MuiTypography-body2'>Fadel S.A (Pollito BB)</Typography>
        </TimelineContent>
        
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0', paddingLeft: '1', paddingRight:'1'}}
          s={{ m: 'auto 0', paddingLeft: '0', paddingRight:'0'}}
          align="right"
          variant="body2"
          color="text.secondary"
        >
        <p className='MuiTypography-body2'>{t("TimelineList.Date")}: {fBajada}. RENSPA: 07.014. 0.54241/04 <br/>
           <CheckIcon color='success'/> <img src='/static/images/logo-senasa.jpg' alt="senasa"/></p>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          
          <TimelineDot  variant="outlined">
            <RepeatIcon />
          </TimelineDot>
          <TimelineConnector  />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '30px', px: 2, paddingLeft: '1', paddingRight:'1' }}>
          <Typography variant="body2" component="span">
          <strong>{t("TimelineList.Integration-Farm")}</strong>
          </Typography>
          <Typography className='MuiTypography-body2'> {t("TimelineList.Farm-Located-In")} {localidad} - {provincia} </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0', paddingLeft: '1', paddingRight:'1' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
         <p className='MuiTypography-body2'>{fFaena} {hPlayaFaena} {t("TimelineList.Official-Number")}  SENASA: 1/164825 <br></br>
         <CheckIcon color='success'/><img src='/static/images/logo-senasa.jpg' alt="senasa"/></p>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector  />
          <TimelineDot color="success">
          <FactoryIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '30px', px: 2, paddingLeft: '1', paddingRight:'1'}}>
          <Typography variant="body2" component="p" >
          <strong className='MuiTypography-body2'>{t("TimelineList.Processing-Plant")}:</strong>
          </Typography>
          <Typography  variant="body2" component="span" >{t("TimelineList.Processing-Plant-in")}, Pronunciamiento, Entre Ríos</Typography>
        </TimelineContent>
        
      </TimelineItem>
    
      <TimelineItem>

      
      </TimelineItem>
      
      <AppBar position="static" color='transparent'>
        
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center"  >
      
          <Toolbar variant="dense" >
            <Typography  color="inherit" component="div" justifyContent="center" textAlign="center" >
            <strong>{t("TimelineList.Norms")}</strong>.
            </Typography>
          </Toolbar>

          </Grid>
      </AppBar>
      <Container >
      <Grid container spacing={0}  direction="row-reverse" justifyContent="space-between" alignItems="center" marginTop={4}>
        <div>
            <img src='/static/images/Haccp100x100.jpeg' alt="haccp" />
        </div>
        <div>
            <img src='/static/images/fadel100x100.jpg' alt="fadel"/>
        </div>
        <div>
            <img src='/static/images/sinTacc100x100.png' alt="senasa"/>
        </div>
        <div>
            <img src='/static/images/SenasaLogo100x100.jpg' alt="senasa"/>
        </div>
        </Grid>
      </Container>
    </Timeline>
   );
  }
}