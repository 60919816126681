import React, {Fragment, useEffect, useState} from "react";


//material
import CssBaseline from '@mui/material/CssBaseline';
import { Grid } from "@mui/material";
import Container from '@mui/material/Container';


//componentes
import Lang from "./components/Lang";
import { DateTimePicker } from "./components/DateTimePicker";
import ImageCarrousel from "./components/ImageCarrousel";
import { TimelineList } from "./components/TimelineList";
import Anim from "./components/Anim";

//axios
import axios, { AxiosError } from "axios";

function App() {

  //seteo el estado de la fecha
  const [date, setdate] = useState('')
  //seteo el estado de la hs y minutos
  const [time, setTime] = useState('')
  //seteo el estado del id del producto
  const[id, setId] = useState()

  const [datos, setDatos] = useState([])

console.log("estos son los datos:" + datos)

  //creo el estado de incubacion
  const [incuba, setIncuba] = useState([])
  const [estirpe, setEstirpe] = useState('')
  //creo el estado de crianza
  const [crianza, setCrianza] = useState([])
  const [localidad, setLocalidad] = useState()
  const [provincia, setProvincia] = useState()
  //creo el estado de faena
  const [faena, setFaena] = useState([])
  const [hPlayaFaena, setHPlayaFaena] = useState()

  //creo el estado de carga
  const [loading, setLoading] = useState()
 
//estado para el manejo de errores
const [muestro, setMuestro] = useState(true)



//seteo de estado para las fechas formateadas
const [fPostura, setFpostura] = useState('')
const [fCarga, setFcarga] = useState('')
const [fBajada, setFbajada] = useState('')
const [fFaena, setFechafaena] = useState('')


  //obtengo lo que viene del Date en el DateTimePicker
  const handleDateApp = (fecha) =>{
    setdate(fecha)
 }
 //obtengo lo que viene del Timer en el DateTimePicker
 const handleTimeApp = (horaMinutos) =>{
   setTime(horaMinutos.toString())
}

//obtengo el id que viene del ImageCarrousel
const handleIdProducto = (id) =>{
 console.log("este es el id: "+ id)
 // chequeo si es que hay un id en el estado
  if(id === 1 || id === 2 || id === 3 || id === 3 || id === 4 ||
    id === 5 || id=== 6|| id === 7 || id === 8 || id === 9 || id=== 10 ||
    id === 11 || id === 12 || id === 13 || id === 14 || id === 15){
    //seteo el estado del id
    setId(id)
    //seteo el estado de la variable muestro para hacer la carga de los datos
    setMuestro(false)
  }
    
 
}

//cambio el estado del loading
const handleEstadoLoading = (estado) => {
  setLoading(estado)

  setTimeout(() => {
    setLoading(false)
    
  }, 12000);


}



 //seteo la hora para transformar el endpoint para la llamada a la api
 var hora = time.slice(0,2)
 //console.log(hora)
 var minutos = time.slice(3,5)
 //console.log(minutos)

 //creo la variable para la consulta que va a ir en axios

  var consulta = "https://apiweb.fadel.com.ar:3001/api/v1/trazapp/"+ date +"/"+ hora +":00/"+ minutos +":00/"+id
  //var consulta = "https://localhost:3001/api/v1/trazapp/"+ date +"/"+ hora +":00/"+ minutos +":00/"+id


 const getData = async () => { 
 
    const resp =  axios.get(consulta).then((res) => {
        
      if(res.data !== null || res.data !== undefined){
        setDatos(res.data)

         // ===================SETEO STRING DE LOCALIDAD CRIANZA ==============================================       
         console.log("localidad: "+res.data.crianza[0].FIG_LOCALIDAD.toLowerCase()) 
        var Localidad = res.data.crianza[0].FIG_LOCALIDAD.toLowerCase()

        setLocalidad(Localidad)

        // =================== /SETEO STRING DE LOCALIDAD CRIANZA ==============================================

         // ===================SETEO STRING DE PROVINCIA CRIANZA ==============================================       
         console.log("provincia: "+res.data.crianza[0].FIG_PROVINCIA.toLowerCase()) 
        var Provincia= res.data.crianza[0].FIG_PROVINCIA.toLowerCase()

        setProvincia(Provincia)

        // =================== /SETEO STRING DE PROVINCIA CRIANZA ==============================================


        // ===================SETEO STRING DE ESTIRPE ==============================================
        var estirpe = res.data.incubacion[0].ESTIRPE
        console.log("aca viene la estirpe: "+ estirpe)
        setEstirpe(estirpe)
        // =================== /SETEO STRING DE ESTIRPE ==============================================
        var hPlayaFaena = res.data.faena[0].FFP_HORA_PLAYA.substring(11,16)
        
        console.log(hPlayaFaena)
        setHPlayaFaena(hPlayaFaena)
        //FFP_HORA_PLAYA 

        // =================== /SETEO STRING DE FFP_HORA_PLAYA FAENA ==============================================
        // ===================SETEO STRING DE FECHA POSTURA ==============================================
        var fechaPostura = res.data.incubacion[0].FIC_FECHA_POSTURA.substring(0,10)
        
        var fechaPostura =  new Date(fechaPostura+"T00:00:00")
        let fecha_Postura = fechaPostura.toLocaleDateString()
        setFpostura(fecha_Postura)
        // =================== /SETEO STRING DE FECHA POSTURA ==============================================

        // ===================SETEO STRING DE FIG_FECHA_CARGA ==============================================
        var fechaCarga = res.data.incubacion[0].FIC_FECHA_CARGA.substring(0,10)
        var fechaCarga = new Date(fechaCarga+"T00:00:00")
        var fecha_Carga = fechaCarga.toLocaleDateString()

        setFcarga(fecha_Carga)
        // =================== /SETEO STRING DE FIG_FECHA_CARGA ==============================================

        // ===================SETEO STRING DE  FICGP_FECHA_BAJADA ==============================================
        //console.log("fecha bajada: "+ res.data.crianza[0].FICGP_FECHA_BAJADA.substring(0,10))
        var fechaBajada = res.data.crianza[0].FICGP_FECHA_BAJADA.substring(0,10)
        var fechaBajadaf = new Date(fechaBajada+"T00:00:00")
        //console.log("fecha bajada despues de 1er formateo: "+ fechaBajadaf)
        var fecha_Bajada = fechaBajadaf.toLocaleDateString()
        //console.log("fecha bajada format "+ fecha_Bajada)
        setFbajada(fecha_Bajada)
        // =================== /SETEO STRING DE  FICGP_FECHA_BAJADA ==============================================

        // =================== SETEO STRING DE  FFP_HORA_PLAYA ==============================================
        console.log("fecha faena: " + res.data.faena[0].FFP_FECHA.substring(0,10))
        var fechaFaena = res.data.faena[0].FFP_FECHA.substring(0,10)
        var fechaFaenaf = new Date(fechaFaena+"T00:00:00")
        var fecha_Faena = fechaFaenaf.toLocaleDateString()
        console.log(fecha_Faena)
        setFechafaena(fecha_Faena)

        // =================== /SETEO STRING DE  FFP_HORA_PLAYA ==============================================

        console.log("estatus:" + res.data.status)
        setIncuba(res.data.incubacion[0])
        console.log("estirpeeee: " + res.data.incubacion[0].ESTIRPE)
        setCrianza(res.data.crianza[0])
        setFaena(res.data.faena[0])
        
        }else {
              setDatos([])
            
            console.log("----estatus:" + res.data.status)
            setIncuba([])
            console.log("-----estirpeeee: no vino" )
            setCrianza([])
            setFaena([])
        }
      
    });
  }

//uso el hook con la funcion

useEffect(() => {  
  if(date !== undefined || date !== ""){
    if(time !== undefined  || time !== "") {
      if(id !== undefined  || id !== "" || id !== 0){
         getData()
      }
    }
  }else{
    setDatos([])
  }
}, [date,time,id])




if(loading){
  return(
    <Fragment >
      <CssBaseline />
      <Container maxWidth="sm" >
          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '12vh' }} > 
            <Grid item xs={3}>
               <Anim />
            </Grid>
        </Grid>
    </Container>

   </Fragment>
  )
}
else if(muestro === true){

  return (
    <Fragment >
      <CssBaseline />
      <Container maxWidth="sm" >

          <Lang/>

          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '12vh' }} > 
            <Grid item xs={3}>

             <DateTimePicker handleDateApp = {handleDateApp} handleTimeApp = {handleTimeApp}/>

          </Grid>
        </Grid> 
      </Container>
        <ImageCarrousel handleIdProducto={handleIdProducto} handleEstadoLoading={handleEstadoLoading}/>

        
      </Fragment>

   );
  }else if(muestro === false){
    return(
      <Fragment >
      <CssBaseline />
      <Container maxWidth="sm" >

          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '12vh' }} > 
            <Grid item xs={3}>
                    <TimelineList provincia={provincia} localidad={localidad} hPlayaFaena={hPlayaFaena} fPostura={fPostura} fCarga={fCarga} fBajada={fBajada} fFaena={fFaena} estirpe={estirpe} />
            </Grid>
        </Grid> 
      </Container>
        

        
      </Fragment>


    )
  }
}
export default App;
